import React from 'react';
import './groomers.scss';
import OfferSection from '../../offer-section/offer-section';
import Image from './image';
import LinkButton from '../../buttons/link-button/link-button';
import { SimpleArrow } from '../../icons/icons';

const descriptioin = 'В нашей команде работаю только настоящие профессионалы истинно любящие своё дело. '
  + 'Перейди в раздел грумеров и найди своего мастера';

const Groomers = () => {
  const Icon = SimpleArrow.right;

  const ROOT_CLASS = 'main-page__groomers';
  const BODY_CLASS = 'main-page__groomers-body';
  const BUTTON_CLASS = 'main-page__groomers-button';
  const IMAGE_CLASS = 'main-page__groomers-image-wrap';

  const ButtonSection = (
    <LinkButton
      to="/groomers"
      className={BUTTON_CLASS}
      theme="black"
      outline
      iconRight={<Icon />}
    >
      выбрать грумера
    </LinkButton>
  );

  return (
    <OfferSection
      className={ROOT_CLASS}
      title="Выбери своего грумера"
      description={descriptioin}
      button={ButtonSection}
      bodyClass={BODY_CLASS}
      asidePosition="right"
    >
      <Image className={IMAGE_CLASS} />
    </OfferSection>
  );
};

export default Groomers;
