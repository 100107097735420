import React from 'react';
import Layout from '../components/layout';
import GiftPackages from '../components/main-page/gifts-packages/gifts-packages';
import FS from '../components/main-page/fs/fs';
import Delivery from '../components/main-page/delivery/delivery';
import YearsPackages from '../components/main-page/years-packages/years-packages';
import Groomers from '../components/main-page/groomers/groomers';
import Subscribe from '../components/main-page/subscribe/subscribe';
import '../components/main-page/main-page.scss';

const IndexPage = () => (
  <Layout pageName="main-page" mainClass="main-page__main">
    <FS />
    <div className="container">
      <Delivery />
      <YearsPackages />
      <Groomers />
    </div>
    <GiftPackages />
    <div className="container">
      <Subscribe />
    </div>
  </Layout>
);

export default IndexPage;
