import React, { useEffect, useRef } from 'react';
import Video from '../../../images/main/delivery/video.mp4';
import Poster from '../../../images/main/delivery/preview.jpg';

function visible(target, partial) {
  const { top, bottom } = target.getBoundingClientRect();

  const viewTop = document.body.scrollTop;
  const viewBottom = viewTop + window.innerHeight;
  const compareTop = partial === true ? bottom : top;
  const compareBottom = partial === true ? top : bottom;

  return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
}

export default (props) => {
  let isAnimated = false; // не использую стейт потому, что из-за него лагает страница при скролле
  const videoRef = useRef();

  const playVideo = () => {
    videoRef.current.play();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (visible(videoRef.current, true) && !isAnimated) {
        playVideo();
        isAnimated = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <video {...props} ref={videoRef} muted poster={Poster}>
      <source src={Video} type="video/mp4" />
    </video>
  );
};
