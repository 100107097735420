import React from 'react';
import Video from './video';
import OfferSection from '../../offer-section/offer-section';
import './delivery.scss';

const description = 'Наша партнёрская программа с московскими зоо-такси даёт возможность вам не отрываться '
  + 'от своих дел, пока ваш любимец приводит себя в порядок.';

// todo alex при скроле бывает лагает из-за видео. Нужно исправить задержку в отрисовке
const Delivery = () => (
  <OfferSection
    className="main-page__delivery"
    title="Доставим вашего питомца до салона и обратно!"
    description={description}
    asidePosition="right"
    bodyClass="main-page__delivery-body"
    asideClass="main-page__delivery-aside"
  >
    <Video className="main-page__delivery-video" />
  </OfferSection>
);

export default Delivery;
