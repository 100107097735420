import React from 'react';
import './gifts-packages.scss';
import { SimpleArrow } from '../../icons/icons';
import Button from '../../buttons/link-button/link-button';

const GiftPackages = () => {
  const Icon = SimpleArrow.right;

  const ROOT_CLASS = 'main-page__gifts-packages';
  const TITLE_CLASS = 'main-page__gifts-packages-title page__title page__title_h2';
  const DESCRIPTION_CLASS = 'main-page__gifts-packages-description';
  const BUTTON_CLASS = 'main-page__gifts-packages-button';

  // todo alex Заменить Button на Link на внутр страницу
  return (
    <div className={ROOT_CLASS}>
      <div className="container">
        <h2 className={TITLE_CLASS}>Дари подарочные карты и получай за это бонусные баллы</h2>
        <div className={DESCRIPTION_CLASS}>
          Пополняем ваш бонусный счёт на 10% от стоимости при дарении подарочной карты
        </div>
        <Button
          to="/gift-cards"
          className={BUTTON_CLASS}
          outline
          iconRight={<Icon />}
        >
          Подарить
        </Button>
      </div>
    </div>
  );
};

export default GiftPackages;
