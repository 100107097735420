import React from 'react';
import Button from '../../buttons/button/button';
import './fs.scss';

const FS = () => {
  const handleClick = () => {
    // eslint-disable-next-line no-unused-expressions
    window.yWidget && window.yWidget.show('https://n313888.yclients.com/');
  };

  return (
    <div className="main-page__fs">
      <div className="container">
        <h1 className="main-page__fs-title page__title page__title_h1">Современный груминг для вашего питомца</h1>
        <Button theme="red" onClick={handleClick} className="main-page__fs-button">Записаться</Button>
      </div>
    </div>
  );
};

export default FS;
